/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import downloadFile from './F37-DG13.pdf';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "SoundX: Online Shopping Web App"), "\n", React.createElement(_components.p, null, "SoundX is a web application for selling audio products online. This app is a deliverable for NTU EEE module, EE4717. The project completed by Haoran and\n", React.createElement("a", {
    href: "https://www.linkedin.com/in/lin-sining-862b35169/"
  }, "Sining")), "\n", React.createElement(_components.h2, null, "Tech Stack"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "HTML, CSS"), "\n", React.createElement(_components.li, null, "JavaScript (Vanilla)"), "\n", React.createElement(_components.li, null, "PHP"), "\n", React.createElement(_components.li, null, "MySQL server"), "\n"), "\n", React.createElement(_components.h2, null, "Features"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "User login and account management"), "\n", React.createElement(_components.li, null, "Browse and filter audio products"), "\n", React.createElement(_components.li, null, "Add to cart and manage products in cart"), "\n", React.createElement(_components.li, null, "Create order and track order status"), "\n"), "\n", React.createElement(_components.h2, null, "Individual Contributions"), "\n", React.createElement(_components.p, null, "In this project, I mainly focus on:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Website Flow Design"), "\n", React.createElement(_components.li, null, "Server-side code in ", React.createElement(_components.strong, null, "PHP")), "\n", React.createElement(_components.li, null, "Database Design and Management"), "\n", React.createElement(_components.li, null, "Data Collection with Web Scrapping"), "\n", React.createElement(_components.li, null, "Data Pre-processing"), "\n", React.createElement(_components.li, null, "Front-end validation with ", React.createElement(_components.strong, null, "JavaScript")), "\n"), "\n", React.createElement(_components.h2, null, "Detailed Report"), "\n", React.createElement("div", {
    className: "pb-4"
  }, React.createElement("span", {
    className: "px-4"
  }, React.createElement("a", {
    href: downloadFile,
    download: true
  }, React.createElement("i", {
    class: "fa-solid fa-file-arrow-down fa-2x"
  })))), "\n", React.createElement(_components.h2, null, "Source code"), "\n", React.createElement("div", {
    className: "pb-4"
  }, React.createElement("span", {
    className: "px-4"
  }, React.createElement("a", {
    href: "https://github.com/Haoran101/EE4717-SoundX"
  }, React.createElement("i", {
    class: "fa-brands fa-github fa-2x"
  })))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
